// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBYd10ZoagRAULDSuvqkqOCLRYUFpfmeR8",
  //authDomain: "cursos-categoria-canal-5d678.firebaseapp.com",
  authDomain: "nucleo.categoriacanal.com",
  databaseURL: "https://cursos-categoria-canal-5d678-default-rtdb.firebaseio.com",
  projectId: "cursos-categoria-canal-5d678",
  storageBucket: "cursos-categoria-canal-5d678.appspot.com",
  messagingSenderId: "688201544887",
  appId: "1:688201544887:web:5fcb24814d7128de064932",
  measurementId: "G-YDVJDD48JY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Lfjxs0lAAAAAFwqal-Mp7tV1SnzTNtcnfPnx7UH'),
  isTokenAutoRefreshEnabled: true
});