import React, { useState, useEffect, Suspense } from "react";

import ScrollToTop from "./util/ScrollToTop";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import './firebase';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, get, update } from "firebase/database";

//import Login from './Login';
//import Signup from './Signup';
//import Recuperar from './Recuperar';

//import Pagamento from './pagamento/Pagamento';

//import Pagar from './pagamento/Pagar';
//import Parcelar from './pagamento/Parcelar';
//import Avista from './pagamento/Avista';
//import Status from './pagamento/Status';

//import Stripe from './pagamento/Stripe';
//import StatusStripe from './pagamento/StatusStripe';

//import Oferta from './pagamento/Oferta';
//import OfertaStripe from './pagamento/OfertaStripe';

//import Inicio from './nucleo/Inicio';
//import Conteudo from './nucleo/Conteudo';
//import Favoritos from './nucleo/Favoritos';
//import Anotacoes from './nucleo/Anotacoes';

//import Perfil from './nucleo/Perfil';
//import Ajuda from './nucleo/Ajuda';
//import Preferencias from './nucleo/Preferencias';

//import Estudo from './nucleo/Estudo';

//import Equipes from './nucleo/Equipes';
//import Tabelas from './nucleo/Tabelas';
//import Confronto  from './nucleo/Confronto';
//import Confronto2  from './nucleo/Confronto2';
//import MatrizTeste  from './nucleo/MatrizTeste';
//import MaximizarParametros  from './nucleo/MaximizarParametros';

//import Admin from './admin/Admin';

const Login = React.lazy(() => import("./Login"));
const Signup = React.lazy(() => import("./Signup"));
const Recuperar = React.lazy(() => import("./Recuperar"));

const Pagamento = React.lazy(() => import("./pagamento/Pagamento"));

const Inicio = React.lazy(() => import("./nucleo/Inicio"));
const Conteudo = React.lazy(() => import("./nucleo/Conteudo"));
const Favoritos = React.lazy(() => import("./nucleo/Favoritos"));
const Anotacoes = React.lazy(() => import("./nucleo/Anotacoes"));

const Perfil = React.lazy(() => import("./nucleo/Perfil"));
const Ajuda = React.lazy(() => import("./nucleo/Ajuda"));
const Preferencias = React.lazy(() => import("./nucleo/Preferencias"));

const Estudo = React.lazy(() => import("./nucleo/Estudo"));

const Equipes = React.lazy(() => import("./nucleo/Equipes"));
const Tabelas = React.lazy(() => import("./nucleo/Tabelas"));
const Confronto = React.lazy(() => import("./nucleo/Confronto"));

//const Pagar = React.lazy(() => import("./pagamento/Pagar"));
//const Status = React.lazy(() => import("./pagamento/Status"));
//const Stripe = React.lazy(() => import("./pagamento/Stripe"));
//const StatusStripe = React.lazy(() => import("./pagamento/StatusStripe"));

const Admin = React.lazy(() => import("./admin/Admin"));

const RedeemBonus = React.lazy(() => import("./pages/RedeemBonus"));

export default function App() {
	
	const db = getDatabase();
	const auth = getAuth();
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [userCase, setUserCase] = useState(null);	
	const [openDrawer, setOpenDrawer] = useState(true);
	const [reloadUser, setReloadUser] = useState(false);
	
	useEffect(() => {
					
		const authValidation = async() =>{
			await auth.onAuthStateChanged(async (user) => {
				
				if(user){
					
					setUser(user);

					const userRef = ref(db, '/users/' + user.uid + '/expiration_date');
					await get(userRef).then(async (snapshot) => {
						
						if (snapshot.exists()) {
							const dateString = snapshot.val();
							const expirationDate = new Date(dateString);
							const currentDate = new Date();
							if (expirationDate.getTime() < currentDate.getTime()) {
								setUserCase(0);
								setLoading(false);
							} else {
								setUserCase(1);
								setLoading(false);
							}
							
						} else {
							
							const verifiedRef = ref(db, '/users/' + user.uid + '/emailVerified');
							await get(verifiedRef).then((snapshotVerified) => {
								if(snapshotVerified.exists()){
									if(snapshotVerified.val()){
										setUserCase(0);
										setLoading(false);
									}else{
										setUserCase(2);
										setLoading(false);
									}
								}else{
									setUserCase(2);
									setLoading(false);
								}
							});
							
						}						
					});
					
					const verifiedRef = ref(db, '/users/' + user.uid + '/emailVerified');
					get(verifiedRef).then((snapshot) => {
						if(snapshot.exists()){
							if(snapshot.val() !== user.emailVerified){
								update(ref(db, '/users/' + user.uid),{
									emailVerified: user.emailVerified,
								});
							}
						}else{
							update(ref(db, '/users/' + user.uid),{
								emailVerified: user.emailVerified,
							});
						}
					});
					
					const userdataRef = ref(db, 'users/' + user.uid + '/email');
					get(userdataRef).then((snapshot)=>{
						if(!snapshot.exists()){
							update(ref(db, 'users/' + user.uid), {
								username: user.displayName,
								email: user.email,						
								preferencias: {
									mail_news: true,
									mail_comments: false,
									noti_news: true,
									noti_comments: true,
								},
							}).then(()=> {
								update(ref(db, 'users_public/' + user.uid), {
									displayName: user.displayName,
								});
							});
						}
					});
					
					
				}else{					
					setUserCase(99);
					setUser('');
					//console.log("Não logado: ");
					//console.log(user);
					setLoading(false);
				}
			});			
		}
		
		authValidation();
		
	}, [loading,auth,db,reloadUser]);
	
	
	const refreshUser = () => {
		if(userCase===2){			
			auth.currentUser.reload().then(()=>{
				setUser(auth.currentUser);
				setReloadUser(!reloadUser);
			});
		}
	}
	
			
	const handleRedirections = (userCase99, userCase0, userCase1, component) => {
				
		//99 - Usuário não logado
		if (userCase === 99){
			if(!userCase99){
				return component;
			}else{
				return (<Navigate to={userCase99} replace />)
			}
		//0 - está logado mas não é membro
		}else if(userCase === 0 || userCase === 2){			
			if(!userCase0){
				return component;
			}else{
				return (<Navigate to={userCase0} replace />)
			}
		//1 - está logado e é membro
		}else if(userCase === 1){			
			if(!userCase1){
				return component;
			}else{
				return (<Navigate to={userCase1} replace />)
			}
		}
	}
	
	/*const adminRedirections = (component) => {

		const adminRef = ref(db, 'admin/users/' + user.uid + '/permission');
		get(adminRef).then((snapshot) => {
			console.log(component);
			if (snapshot.exists()) {
				return component;
			}else{
				return (<Navigate to="/" replace />);
			}
		});
	}*/

	if (loading) {		
		return (
			<Box sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100vh'
				}}>
				<CircularProgress />
			</Box>
		);
	}else{
		return(
			<BrowserRouter onNavigation={() => setLoading(true)}>
				<ScrollToTop />
				<Suspense fallback={<></>}>
					<Routes>
						
						<Route path="/entrar" element={handleRedirections(false,"/inicio","/inicio",<Login setLoading={setLoading} />)} />
						<Route path="/recuperar" element={<Recuperar />} />
						<Route path="/inscricao" element={handleRedirections(false,"/inicio","/inicio",<Signup setLoading={setLoading} />)} />
											
						<Route path="/planos" element={handleRedirections("/inscricao",false,"/inicio",<Pagamento user={user} setLoading={setLoading} />)} />
						
						{/*<Route path="/pagar" element={handleRedirections("/inscricao",false,"/inicio",<Pagar user={user} />)} />*/}
						{/*<Route path="/pagamento/parcelar/:id" element={handleRedirections("/inscricao",false,"/inicio",<Parcelar user={user}/>)} />*/}
						{/*<Route path="/pagamento/avista/:id" element={handleRedirections("/inscricao",false,"/inicio",<Avista user={user} />)} />*/}
						{/*<Route path="/pagamento/status/:id" element={handleRedirections("/inscricao",false,false,<Status user={user} />)} />*/}
						{/*<Route path="/pagamento/oferta" element={handleRedirections("/inscricao",false,false,<Oferta user={user} />)} />*/}
						{/*<Route path="/pagamento/ofertastripe" element={handleRedirections("/inscricao",false,false,<OfertaStripe user={user} />)} />*/}
						
						{/*<Route path="/pagamento/stripe/:id" element={handleRedirections("/inscricao",false,"/inicio",<Stripe user={user} />)} />*/}
						{/*<Route path="/pagamento/status-stripe/:id" element={handleRedirections("/inscricao",false,false,<StatusStripe user={user} />)} />*/}
						
						<Route path="/" element={handleRedirections("/inicio","/inicio","/inicio",<Inicio setLoading={setLoading} user={user} userCase={userCase} />)} />
						<Route path="/inicio" element={handleRedirections(false,false,false,<Inicio setLoading={setLoading} user={user} userCase={userCase} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />)} />
						<Route path="/conteudo" element={handleRedirections(false,false,false,<Conteudo setLoading={setLoading} user={user} userCase={userCase} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />)} />
						
						<Route path="/equipes" element={handleRedirections(false,false,false,<Equipes setLoading={setLoading} user={user} userCase={userCase} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}/>)} />
						<Route path="/equipes/:team" element={handleRedirections(false,false,false,<Equipes setLoading={setLoading} user={user} userCase={userCase} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}/>)} />
						<Route path="/tabelas/:league" element={handleRedirections(false,false,false,<Tabelas setLoading={setLoading} user={user} userCase={userCase} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}/>)} />
						<Route path="/confronto" element={handleRedirections(false,false,false,<Confronto setLoading={setLoading} user={user} userCase={userCase} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}/>)} />
						{/*<Route path="/confronto2" element={handleRedirections(false,false,false,<Confronto2 setLoading={setLoading} user={user} userCase={userCase} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}/>)} />*/}
						{/*<Route path="/matriz" element={handleRedirections(false,false,false,<MatrizTeste setLoading={setLoading} user={user} userCase={userCase} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}/>)} />*/}
						{/*<Route path="/maximizar" element={handleRedirections(false,false,false,<MaximizarParametros setLoading={setLoading} user={user} userCase={userCase} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}/>)} />*/}
						
						<Route path="/favoritos" element={handleRedirections(false,false,false,<Favoritos setLoading={setLoading} user={user} userCase={userCase} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}/>)} />
						<Route path="/anotacoes" element={handleRedirections(false,false,false,<Anotacoes setLoading={setLoading} user={user} userCase={userCase} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}/>)} />
						
						<Route path="/perfil" element={handleRedirections("/entrar",false,false,<Perfil refreshUser={refreshUser} setLoading={setLoading} user={user} userCase={userCase} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />)} />
						<Route path="/preferencias" element={handleRedirections("/entrar",false,false,<Preferencias setLoading={setLoading} user={user} userCase={userCase} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />)} />
						<Route path="/ajuda" element={handleRedirections("/entrar",false,false,<Ajuda setLoading={setLoading} user={user} userCase={userCase} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />)} />
						
						<Route path="/estudo/:estudo/" element={handleRedirections(false,false,false,<Estudo setLoading={setLoading} user={user} userCase={userCase} />)} />
						<Route path="/estudo/:estudo/:post" element={handleRedirections(false,false,false,<Estudo refreshUser={refreshUser} setLoading={setLoading} user={user} userCase={userCase} />)} />
						
						<Route path="/admin/:view" element={<Admin setLoading={setLoading} user={user} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />} />					

						<Route path="/redeemBonus/:bonusUid" element={<RedeemBonus user={user} userCase={userCase} />} />
						
						<Route path="*" element={handleRedirections("/inicio","/inicio","/inicio",<NotFound />)} />
					</Routes>
				</Suspense>
			</BrowserRouter>
		);
	}
}

const NotFound = () =>{
	return (
		<div>404</div>
	);
}
